import styled from "styled-components";
import tw from "twin.macro";

import { BaseExpander } from "../../shared/components/BaseExpander";

export const Container = tw(BaseExpander)`
  flex justify-center
`;

export const Link = styled.a.attrs({
  target: "_blank",
})`
  ${tw`
     text-xs text-blue-light
     font-semibold
     underline
     hover:text-brand
  `}
`;

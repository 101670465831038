import { useCallback } from "react";

import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";

import { TransactionErrorMainButton } from "./TransactionErrorMainButton";

import type { TokenData } from "../../queries/types/Token";
import type { useTokenApproveTransaction } from "../../transactions/hooks/useTokenApproveTransaction";
import type { FC } from "react";

interface ApproveMainButtonProps {
  tokenApproveTransaction: ReturnType<typeof useTokenApproveTransaction>;
  spenderAddress: string | undefined;
  tokenData?: TokenData;
}

export const ApproveMainButton: FC<ApproveMainButtonProps> = ({
  tokenApproveTransaction,
  spenderAddress,
  tokenData,
}) => {
  const { mutation, resetTransaction, runTransaction } =
    tokenApproveTransaction;

  const { isError, isLoading } = mutation;

  const handleButtonClick = useCallback(() => {
    if (spenderAddress) {
      runTransaction(spenderAddress);
    }
  }, [runTransaction, spenderAddress]);

  if (isError) {
    return (
      <TransactionErrorMainButton
        mutation={mutation}
        resetTransaction={resetTransaction}
      />
    );
  }

  const prefix = isLoading ? "Approving" : "Approve";
  const postfix = isLoading ? "..." : "";
  const title = tokenData
    ? `${prefix} ${tokenData.symbol}${postfix}`
    : `${prefix}${postfix}`;

  return (
    <BigButton
      color={BigButtonColor.WHITE}
      disabled={!spenderAddress}
      isLoading={isLoading}
      onClick={handleButtonClick}
    >
      {title}
    </BigButton>
  );
};

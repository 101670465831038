import { useEffect, useState } from "react";

import { areAddressesEqual } from "../../web3/helpers/addresses";
import { getWrappedNativeCoinAddress } from "../../web3/helpers/getWrappedNativeCoinAddress";

import type { TokenData } from "../../queries/types/Token";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

export const useTokenData = (
  defaultTokens: TokenData[],
  nativeCoinData?: TokenData,
  chainId?: ChainId
) => {
  const defaultTokenDataAddress = defaultTokens[0]?.address;

  const [tokenDataAddress, setTokenDataAddress] = useState(
    defaultTokenDataAddress
  );

  useEffect(() => {
    setTokenDataAddress(defaultTokenDataAddress);
  }, [defaultTokenDataAddress]);

  const wrappedNativeCoinAddress = chainId
    ? getWrappedNativeCoinAddress(chainId)
    : null;

  const wrappedNativeCoinDataIndex = defaultTokens.findIndex((tokenData) =>
    tokenData && wrappedNativeCoinAddress
      ? areAddressesEqual(tokenData.address, wrappedNativeCoinAddress)
      : false
  );

  const shouldAddNativeCoinData =
    nativeCoinData && wrappedNativeCoinDataIndex >= 0;
  const tokensWithNativeCoin = [
    ...defaultTokens.slice(0, wrappedNativeCoinDataIndex + 1),
    nativeCoinData,
    ...defaultTokens.slice(wrappedNativeCoinDataIndex + 1),
  ];

  const tokens = shouldAddNativeCoinData ? tokensWithNativeCoin : defaultTokens;

  const tokenData = tokens.find((token) => token?.address === tokenDataAddress);

  return [tokenData, tokens, setTokenDataAddress] as const;
};

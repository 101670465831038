import tw from "twin.macro";

export const CellContent = tw.div`
  flex flex-col
  gap-1
`;

export const SubTitle = tw.span`
  font-semibold
  text-xs
  text-gray-newLight
`;

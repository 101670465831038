import { useQueries } from "react-query";

import { useWallet } from "../../web3/hooks/useWallet";
import { getTokenQueryOptions } from "../helpers/queriesOptions";

import type { TokenQueryParameters } from "../types/TokenQueryParameters";

export const useTokensQueries = (
  tokensQueriesParameters: TokenQueryParameters[]
) => {
  const { account } = useWallet();

  const queriesOptions = tokensQueriesParameters.map(
    ({ chainId, tokenAddress, spenderAddress }) =>
      getTokenQueryOptions(chainId, tokenAddress, spenderAddress, account)
  );

  return useQueries(queriesOptions);
};

import { AnimatePresence } from "framer-motion";

import { Container } from "../styles/BaseExpander";

import type { FC } from "react";

interface BaseExpanderProps {
  isExpanded: boolean;
  children: React.ReactNode;
  className?: string;
}

export const BaseExpander: FC<BaseExpanderProps> = ({
  isExpanded,
  children,
  className,
}) => (
  <AnimatePresence>
    {isExpanded ? (
      <Container>
        <div className={className}>{children}</div>
      </Container>
    ) : null}
  </AnimatePresence>
);

import { useCallback } from "react";

import { connectWalletModalType } from "../../connect-wallet-modal/constants/connectWalletModalType";
import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";
import { useModal } from "../hooks/useModal";

export const ConnectWalletMainButton = () => {
  const { pushModal } = useModal();

  const showConnectWalletModal = useCallback(() => {
    pushModal(connectWalletModalType);
  }, [pushModal]);

  return (
    <BigButton color={BigButtonColor.BLUE} onClick={showConnectWalletModal}>
      Connect Wallet
    </BigButton>
  );
};

import { useQuery } from "react-query";

import { getTokenQueryOptions } from "../helpers/queriesOptions";

import type { TokenQueryParameters } from "../types/TokenQueryParameters";

export const useTokenQuery = ({
  chainId,
  tokenAddress,
  spenderAddress,
  account,
}: TokenQueryParameters) => {
  const queryOptions = getTokenQueryOptions(
    chainId,
    tokenAddress,
    spenderAddress,
    account
  );

  return useQuery(queryOptions);
};

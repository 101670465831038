import { TokenInputError } from "../../input-card/types/TokenInputError";
import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";

import type { TokenData } from "../../queries/types/Token";
import type { TokenInputState } from "@roe-monorepo/shared-features/src/input-card/types/TokenInputState";
import type { FC } from "react";

interface TokenErrorMainButtonProps extends Pick<TokenInputState, "error"> {
  tokenData?: TokenData;
}

export const TokenErrorMainButton: FC<TokenErrorMainButtonProps> = ({
  error,
  tokenData,
}) => {
  if (!error) {
    return null;
  }

  let buttonTitle = "";

  if (error === TokenInputError.INSUFFICIENT_TOKEN_BALANCE) {
    buttonTitle = tokenData
      ? `Insufficient ${tokenData.symbol} Balance`
      : "Insufficient Balance";
  } else if (error === TokenInputError.INSUFFICIENT_TOKEN_INPUT) {
    buttonTitle = tokenData
      ? `Insufficient ${tokenData.symbol} Input`
      : "Insufficient Input";
  } else {
    buttonTitle = "";
  }

  return (
    <BigButton color={BigButtonColor.RED} disabled>
      {buttonTitle}
    </BigButton>
  );
};

import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const Container = styled(motion.div).attrs({
  initial: { height: 0 },
  animate: { height: "auto" },
  exit: { height: 0 },
  transition: { ease: "linear", duration: 0.15 },
})`
  ${tw`overflow-hidden`}
`;

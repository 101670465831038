import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";

import type { useBaseTransaction } from "@roe-monorepo/shared-features/src/transactions/hooks/useBaseTransaction";
import type { FC } from "react";

interface TransactionErrorMainButtonProps {
  mutation: Pick<ReturnType<typeof useBaseTransaction>["mutation"], "error">;
  resetTransaction: ReturnType<typeof useBaseTransaction>["resetTransaction"];
}

export const TransactionErrorMainButton: FC<
  TransactionErrorMainButtonProps
> = ({ mutation, resetTransaction }) => {
  const { error } = mutation;
  const message = error?.message;

  return (
    <BigButton color={BigButtonColor.RED} onClick={resetTransaction}>
      {message}
    </BigButton>
  );
};

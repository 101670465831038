import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";
import { useModal } from "../hooks/useModal";

import type { FC } from "react";

interface SuccessfulMainButtonProps {
  title: string;
}

export const SuccessfulMainButton: FC<SuccessfulMainButtonProps> = ({
  title,
}) => {
  const { hideModal } = useModal();

  return (
    <BigButton
      color={BigButtonColor.GRADIENT}
      onClick={() => {
        hideModal();
      }}
    >
      {title}
    </BigButton>
  );
};

import { toBig, getExp, getZero } from "../../shared/helpers/bigjs";
import { IERC20__factory as ERC20Factory } from "../../smart-contracts/types";
import { getProvider } from "../../web3/helpers/getProvider";

import type { Token } from "@roe-monorepo/shared-features/src/queries/types/Token";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

export const tokenFetcher = async (
  chainId: ChainId,
  tokenAddress: string,
  spenderAddress?: string,
  account?: string
): Promise<Token> => {
  const provider = getProvider(chainId);
  const address = tokenAddress;

  const tokenContract = ERC20Factory.connect(address, provider);

  const [symbol, decimals, rawBalance, rawAllowance, rawTotalSupply] =
    await Promise.all([
      tokenContract.symbol(),
      tokenContract.decimals(),
      account ? tokenContract.balanceOf(account).then(toBig) : null,
      spenderAddress && account
        ? tokenContract.allowance(account, spenderAddress).then(toBig)
        : getZero(),
      tokenContract.totalSupply().then(toBig),
    ]);

  const divisor = getExp(decimals);

  const balance = rawBalance ? rawBalance.div(divisor) : null;
  const allowance = rawAllowance.div(divisor);
  const totalSupply = rawTotalSupply.div(divisor);

  return { address, symbol, decimals, balance, allowance, totalSupply };
};

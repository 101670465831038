import { getExplorerLink } from "../helpers/getExplorerLink";
import { Container, Link } from "../styles/TransactionLink";
import { ExplorerLinkType } from "../types/ExplorerLinkType";

import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type { FC } from "react";

interface TransactionLinkProps {
  chainId?: ChainId;
  transactionHash?: string;
}

export const TransactionLink: FC<TransactionLinkProps> = ({
  chainId,
  transactionHash,
}) => {
  const link =
    chainId && transactionHash
      ? getExplorerLink(chainId, ExplorerLinkType.TX, transactionHash)
      : undefined;

  const isExpanded = Boolean(transactionHash);

  return (
    <Container isExpanded={isExpanded}>
      <Link href={link}>View on explorer</Link>
    </Container>
  );
};

import { useCallback } from "react";

import { usePairModalState } from "../hooks/usePairModalState";
import { useZapContentState } from "../hooks/useZapContentState";
import { ActionContainer, Container } from "../styles/DepositTypeSelector";
import { ZapType } from "../types/ZapType";

export const DepositTypeSelector = () => {
  const {
    zapType: currentZapType,
    setZapType,
    token0State,
    token1State,
  } = useZapContentState();
  const { supplyTokenState, withdrawTokenState } = usePairModalState();

  const handleButtonClick = useCallback(
    (zapType: ZapType) => {
      setZapType(zapType);

      supplyTokenState.setInputValue("");
      withdrawTokenState.setInputValue("");
      token0State.setInputValue("");
      token1State.setInputValue("");
    },
    [setZapType, supplyTokenState, withdrawTokenState, token0State, token1State]
  );

  return (
    <Container>
      <ActionContainer
        isActive={currentZapType === ZapType.LP}
        onClick={() => {
          handleButtonClick(ZapType.LP);
        }}
      >
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 3C8.71036 3 5.75 4.67619 5.75 6.80952V17.1905C5.75 19.3238 8.71036 21 12.5 21C16.2896 21 19.25 19.3238 19.25 17.1905V6.80952C19.25 4.67619 16.2896 3 12.5 3ZM8.41143 5.02857C9.49143 4.45714 10.9475 4.14286 12.5 4.14286C14.0525 4.14286 15.5086 4.45714 16.5886 5.02857C17.6686 5.6 18.0929 6.17143 18.0929 6.80952C18.0929 7.44762 17.5432 8.09524 16.5886 8.59048C15.6339 9.08571 14.0525 9.47619 12.5 9.47619C10.9475 9.47619 9.49143 9.1619 8.41143 8.59048C7.33143 8.01905 6.90714 7.44762 6.90714 6.80952C6.90714 6.17143 7.45679 5.52381 8.41143 5.02857ZM16.5886 18.9714C15.5086 19.5429 14.0525 19.8571 12.5 19.8571C10.9475 19.8571 9.49143 19.5429 8.41143 18.9714C7.33143 18.4 6.90714 17.8286 6.90714 17.1905V8.97143C8.1125 9.97143 10.1471 10.619 12.5 10.619C14.8529 10.619 16.8875 9.97143 18.0929 8.97143V17.1905C18.0929 17.8286 17.5432 18.4762 16.5886 18.9714Z"
            fill="#C4C4C4"
          />
          <path
            clipRule="evenodd"
            d="M18.0929 17.1905C18.0929 17.8286 17.5432 18.4762 16.5886 18.9714C15.5086 19.5429 14.0525 19.8571 12.5 19.8571C10.9475 19.8571 9.49143 19.5429 8.41143 18.9714C7.33143 18.4 6.90714 17.8286 6.90714 17.1905V8.97143C7.19538 9.21056 7.53105 9.42954 7.90714 9.62434C9.1038 10.2441 10.7098 10.619 12.5 10.619C14.2902 10.619 15.8962 10.2441 17.0929 9.62434C17.469 9.42954 17.8046 9.21056 18.0929 8.97143V17.1905ZM8.41143 5.02857C9.49143 4.45714 10.9475 4.14286 12.5 4.14286C14.0525 4.14286 15.5086 4.45714 16.5886 5.02857C17.6686 5.6 18.0929 6.17143 18.0929 6.80952C18.0929 7.44762 17.5432 8.09524 16.5886 8.59048C15.6339 9.08571 14.0525 9.47619 12.5 9.47619C10.9475 9.47619 9.49143 9.1619 8.41143 8.59048C7.33143 8.01905 6.90714 7.44762 6.90714 6.80952C6.90714 6.17143 7.45679 5.52381 8.41143 5.02857ZM5.75 6.80952C5.75 4.67619 8.71036 3 12.5 3C16.2896 3 19.25 4.67619 19.25 6.80952V17.1905C19.25 19.3238 16.2896 21 12.5 21C8.71036 21 5.75 19.3238 5.75 17.1905V6.80952Z"
            fill="#C4C4C4"
            fillRule="evenodd"
          />
        </svg>
        LP
      </ActionContainer>
      <ActionContainer
        isActive={currentZapType === ZapType.ZAP}
        onClick={() => {
          handleButtonClick(ZapType.ZAP);
        }}
      >
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.751 4.06799C15.8489 4.12473 15.9246 4.21298 15.9658 4.31833C16.0071 4.42367 16.0114 4.53989 15.978 4.64799L14.177 10.5H17.5C17.5977 10.4999 17.6932 10.5285 17.7748 10.5821C17.8564 10.6358 17.9205 10.7121 17.9592 10.8018C17.9979 10.8914 18.0096 10.9905 17.9926 11.0866C17.9757 11.1828 17.931 11.2719 17.864 11.343L9.864 19.843C9.78659 19.9253 9.68359 19.979 9.57177 19.9952C9.45996 20.0115 9.34593 19.9894 9.24827 19.9326C9.15061 19.8758 9.07508 19.7875 9.03399 19.6823C8.9929 19.577 8.98868 19.461 9.022 19.353L10.823 13.5H7.5C7.40235 13.5 7.30682 13.4715 7.22521 13.4178C7.1436 13.3642 7.07948 13.2878 7.04077 13.1982C7.00205 13.1085 6.99044 13.0095 7.00737 12.9133C7.02429 12.8172 7.06901 12.728 7.136 12.657L15.136 4.15699C15.2133 4.07479 15.3162 4.02116 15.4278 4.00482C15.5395 3.98848 15.6534 4.01039 15.751 4.06699V4.06799Z"
            fill="#C4C4C4"
          />
          <path
            clipRule="evenodd"
            d="M15.136 4.15699L7.136 12.657C7.06901 12.728 7.02429 12.8172 7.00737 12.9133C6.99044 13.0095 7.00205 13.1085 7.04077 13.1982C7.07948 13.2878 7.1436 13.3642 7.22521 13.4178C7.30682 13.4715 7.40235 13.5 7.5 13.5H10.823L9.022 19.353C8.98868 19.461 8.9929 19.577 9.03399 19.6823C9.07508 19.7875 9.15061 19.8758 9.24827 19.9326C9.34593 19.9894 9.45996 20.0115 9.57177 19.9952C9.68359 19.979 9.78659 19.9253 9.864 19.843L17.864 11.343C17.931 11.2719 17.9757 11.1828 17.9926 11.0866C18.0096 10.9905 17.9979 10.8914 17.9592 10.8018C17.9205 10.7121 17.8564 10.6358 17.7748 10.5821C17.6932 10.5285 17.5977 10.4999 17.5 10.5H14.177L15.978 4.64799C16.0114 4.53989 16.0071 4.42367 15.9658 4.31833C15.9246 4.21298 15.8489 4.12473 15.751 4.06799V4.06699C15.6534 4.01039 15.5395 3.98848 15.4278 4.00482C15.3645 4.01408 15.304 4.03533 15.2494 4.06699C15.2489 4.06732 15.2483 4.06765 15.2477 4.06799C15.2067 4.09199 15.169 4.12188 15.136 4.15699ZM14.3898 6.40894L8.65701 12.5H12.177L10.6105 17.5907L16.343 11.5H12.823L14.3898 6.40894ZM9.1358 19.1576C9.13574 19.1577 9.13586 19.1576 9.1358 19.1576Z"
            fill="#C4C4C4"
            fillRule="evenodd"
          />
        </svg>
        Zap
      </ActionContainer>
      <ActionContainer
        isActive={currentZapType === ZapType.DUAL}
        onClick={() => {
          handleButtonClick(ZapType.DUAL);
        }}
      >
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z"
            fill="#C4C4C4"
            fillRule="evenodd"
          />
        </svg>
        Add
      </ActionContainer>
    </Container>
  );
};
